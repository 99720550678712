





































































import Vue from 'vue';
import { apolloClient } from '@/apolloClient';
import ScheduleWithBooking from './SchduleWithBooking.vue';
import Spinner from '@/components/Spinner.vue';
import { BookingService, ScheduleInfo } from '../service/BookingService';
import {
  calendarTools,
  Calendar,
  CalendarDay,
} from '@/common/util/CalendarUtil';

const service = new BookingService(apolloClient);

export default Vue.extend({
  name: 'BookingMonthCalendar',
  components: { ScheduleWithBooking, Spinner },
  data() {
    return {
      yearMonth: calendarTools.getCurrentYearMonth(),
      loading: false,
      schedules: {} as Record<string, ScheduleInfo[]>,
    };
  },
  computed: {
    calendar(): Calendar {
      return calendarTools.buildCalendar(this.yearMonth);
    },
  },
  mounted() {
    this.loadSchedules();
  },
  methods: {
    refresh() {
      console.log('refresh');
      this.loadSchedules();
    },
    prevMonth() {
      console.log('prevMonth');
      this.yearMonth = calendarTools.getPrevMonth(this.yearMonth);
      this.loadSchedules();
    },
    nextMonth() {
      console.log('nextMonth');
      this.yearMonth = calendarTools.getNextMonth(this.yearMonth);
      this.loadSchedules();
    },
    dayColor(day: CalendarDay): string {
      if (!day.currentMonth) {
        return 'text-muted';
      } else if (day.date.getDay() == 0) {
        return 'text-frip-danger';
      } else if (day.date.getDay() === 6) {
        return 'text-frip-primary';
      } else {
        return day.today ? 'text-bold' : '';
      }
    },
    loadSchedules() {
      this.loading = true;
      const hostId = this.$store.state.userToken.hostId;
      service
        .loadSchedulesWithBooking(this.calendar.term, hostId)
        .then(result => {
          this.schedules = result;
          this.loading = false;
        });
    },
    clickSchedule(schedule: ScheduleInfo) {
      if (schedule.isTicketSchedule) {
        this.$emit('selectTicket', schedule.booking.id);
      } else {
        this.$emit('selectSchedule', schedule.id);
      }
    },
  },
});
