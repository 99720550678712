







import Vue from 'vue';
import BookingScheduleView from '../components/BookingScheduleView.vue';
import BookingItemListForSchedule from '../components/BookingItemListForSchedule.vue';

export default Vue.extend({
  name: 'BookingScheduleViewContainer',
  components: { BookingScheduleView, BookingItemListForSchedule },
  data() {
    return {
      scheduleId: this.$route.params.id,
    };
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        this.refreshList();
      }, 200);
    },
    refreshList() {
      const list = this.$refs.items as InstanceType<
        typeof BookingItemListForSchedule
      >;
      list.refetchItems();
    },
  },
});
