


































import { ContainerMixin } from '@/common/mixin/containerMixin';
import {
  ScheduleCancellationReason,
  ScheduleCancellationReasonLabels,
} from '../model/ScheduleCancellationReason';
import { ScheduleService } from '../service/ScheduleService';
import { apolloClient } from '@/apolloClient';
import { BookingService } from '../service/BookingService';

const service = new ScheduleService(apolloClient);
const bookingService = new BookingService(apolloClient);

export default ContainerMixin.extend({
  name: 'ScheduleCancelPopup',
  data() {
    return {
      opened: false,
      scheduleId: '',
      reasonId: '' as ScheduleCancellationReason,
      reasons: ScheduleCancellationReasonLabels.filter(
        i => i.onlySystem == false
      ),
      comment: '',
    };
  },
  computed: {
    commentRequired(): boolean {
      return (
        this.reasons.find(i => i.value == this.reasonId)?.requireComment ||
        false
      );
    },
  },
  methods: {
    open(scheduleId: string): void {
      this.scheduleId = scheduleId;
      this.reasonId = '' as ScheduleCancellationReason;
      this.comment = '';
      this.opened = true;
    },
    async clickOk(): Promise<void> {
      console.log('clicked');
      if (!this.reasonId) {
        return this.$modal.show({
          title: '안내',
          message: '일정 취소 사유를 선택해 주세요.',
          type: 'warning',
        });
      }
      if (this.comment.trim().length == 0 && this.commentRequired) {
        return this.$modal.show({
          title: '안내',
          message: '자세한 일정 취소 사유를 작성해주세요.',
          type: 'warning',
        });
      }
      const result = await bookingService.checkCancelableBySchedule(
        this.scheduleId
      );

      if (result.available == false) {
        console.log('checkCancelableBySchedule:', result.errors);
        return this.$modal.show({
          title: '안내',
          message: result.errors[0].message,
          type: 'warning',
        });
      }

      try {
        await service.cancel(this.scheduleId, {
          reason: this.reasonId,
          message: this.comment,
        });
        this.showSuccessMessage(
          '일정 취소 성공',
          '일정 취소가 완료 되었습니다.'
        );
        this.$emit('success');
        this.opened = false;
      } catch (error) {
        this.showErrorAlert(error, '일정 취소 실패');
      }
    },
  },
});
