import gql from 'graphql-tag';

export const TICKET_CONFIRM_MUTATION = gql`
  mutation ConfirmTicket($ticketId: ID!, $term: ScheduleTermInput) {
    confirmTicket(ticketId: $ticketId, term: $term) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const TICKET_USE_MUTATION = gql`
  mutation UseTicket($ticketId: ID!) {
    useTicket(ticketId: $ticketId) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const TICKET_APPLY_MUTATION = gql`
  mutation ApplyTickets($params: [TicketActionParamInput!]!) {
    applyTickets(params: $params) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const TICKET_RESTORE_CONFIRMATION_MUTATION = gql`
  mutation RestoreTicketConfirmation($ticketId: ID!, $reason: String!) {
    restoreTicketConfirmation(ticketId: $ticketId, reason: $reason) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const TICKET_RESTORE_USING_MUTATION = gql`
  mutation RestoreTicketUsing($ticketId: ID!, $reason: String!) {
    restoreTicketUsing(ticketId: $ticketId, reason: $reason) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const TICKET_RESTORE_ABSENCE_MUTATION = gql`
  mutation RestoreTicketAbsence($ticketId: ID!, $reason: String!) {
    restoreTicketAbsence(ticketId: $ticketId, reason: $reason) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const SCHEDULE_OPEN_MUTATION = gql`
  mutation OpenSchedule($scheduleId: ID!) {
    openSchedule(id: $scheduleId) {
      id
      status
    }
  }
`;

export const SCHEDULE_PAUSE_MUTATION = gql`
  mutation PauseSchedule($scheduleId: ID!) {
    pauseSchedule(id: $scheduleId) {
      id
      status
    }
  }
`;

export const SCHEDULE_CANCEL_MUTATION = gql`
  mutation CancelSchedule(
    $scheduleId: ID!
    $param: ScheduleCancellationParamInput!
  ) {
    cancelSchedule(id: $scheduleId, param: $param) {
      id
      status
    }
  }
`;

export const REQUEST_EXCEL_EXPORT_MUTATION = gql`
  mutation RequestBookingExcelExport($filter: BookingFilterInput!) {
    requestBookingExcelExport(filter: $filter) {
      id
    }
  }
`;

export const BOOKING_EXCEL_REQUEST_QUERY = gql`
  query BookingExcelRequest($id: ID!) {
    booking {
      bookingExportRequest(id: $id) {
        id
        status
        url
      }
    }
  }
`;
