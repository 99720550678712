




import Vue from 'vue';
import { BookingStateLabels } from '../../model/BookingState';

export default Vue.extend({
  name: 'BookingStatusLabel',
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    label: function (): string {
      return (
        BookingStateLabels.find(i => i.value.toString() == this.status)
          ?.label || ''
      );
    },
  },
});
