































































































































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import { TicketState } from '../model/TicketState';
import TicketStatusLabel from './controls/TicketStatusLabel.vue';
import { BookingService } from '../service/BookingService';
import { apolloClient } from '@/apolloClient';
import { TICKETS_BY_SCHEDULE_QUERY } from '../graphql/query';
import { TicketAction } from '../model/TicketAction';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { ApolloQueryResult } from 'apollo-client';
import { Connection } from '@frientrip/domain';

interface Ticket {
  id: string;
  availableActions: TicketAction[];
  scheduleTerm: {
    startedAt: number;
    endedAt: number;
    duration: number;
  } | null;
  confirmedAt: number;
  usedAt: number;
  status: TicketState;
  bookingItem: BookingItem;
}

interface BookingItem {
  id: string;
  indexNo: number;
  replies: Reply[];
  booking: Booking;
}

interface Booking {
  id: string;
  bookingConfirmationEnabled: boolean;
  customer: {
    id: string;
    nickname: string;
    gender: string;
    email: string;
    mobileNumber: string;
  };
  info: {
    id: string;
    name: string;
  };
}

interface Reply {
  id: string;
  title: string;
  answer: string;
  selections: { label: string; value: string }[];
}

const service = new BookingService(apolloClient);

export default ContainerMixin.extend({
  name: 'BookingItemListForSchedule',
  mixins: [ContainerMixin],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    TicketStatusLabel,
    // BaseAlert,
  },
  props: {
    scheduleId: {
      type: String,
    },
  },
  data() {
    return {
      items: [] as Ticket[],
      selection: [] as Ticket[],
      totalCount: 0,
      size: 300,
    };
  },
  apollo: {
    items: {
      query: TICKETS_BY_SCHEDULE_QUERY,
      variables(): { scheduleId: string; page: number; size: number } {
        return {
          scheduleId: this.scheduleId,
          page: Number(this.$route.query.page) || 1,
          size: this.size,
        };
      },
      result(
        result: ApolloQueryResult<{
          booking: { tickets: Connection<Ticket> };
        }>
      ) {
        this.totalCount = result.data.booking.tickets.totalCount;
      },
      update: data =>
        data.booking.tickets.edges.map((i: { node: Ticket }) => i.node),
    },
  },
  computed: {
    enableConfirm(): boolean {
      return (
        this.selection.length > 0 &&
        this.selection.every(i => this.canConfirmTicket(i))
      );
    },
    enableUsing(): boolean {
      return (
        this.selection.length > 0 &&
        this.selection.every(i => this.canUseTicket(i))
      );
    },
    enableAbsent(): boolean {
      return (
        this.selection.length > 0 &&
        this.selection.every(i => this.canAbsentTicket(i))
      );
    },
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
  },
  methods: {
    handleSelectionChange(items: Ticket[]): void {
      console.log('handleSelectionChange:', items);
      this.selection = items;
    },
    async refetchItems() {
      await this.$apollo.queries.items.refetch();
    },
    getGenderName(item: Ticket): string {
      let text = '-';
      switch (item.bookingItem.booking.customer?.gender) {
        case 'M':
          text = '남성';
          break;
        case 'F':
          text = '여성';
          break;
      }
      return text;
    },
    getReply(reply: Reply): string {
      return (
        reply.title +
        ' : ' +
        reply.answer +
        reply.selections.map(i => i.label).join(', ')
      );
    },
    canConfirmTicket(ticket: Ticket): boolean {
      return ticket.availableActions.includes(TicketAction.CONFIRMATION);
    },
    canUseTicket(ticket: Ticket): boolean {
      return ticket.availableActions.includes(TicketAction.USING);
    },
    canAbsentTicket(ticket: Ticket): boolean {
      return ticket.availableActions.includes(TicketAction.USING);
    },
    canRestoreConfirmationTicket(ticket: Ticket): boolean {
      return (
        ticket.status == TicketState.CONFIRMED &&
        ticket.availableActions.includes(TicketAction.RESTORATION)
      );
    },
    canRestoreUsingTicket(ticket: Ticket): boolean {
      return (
        ticket.status == TicketState.USED &&
        ticket.availableActions.includes(TicketAction.RESTORATION)
      );
    },
    canRestoreAbsenceTicket(ticket: Ticket): boolean {
      return (
        ticket.status == TicketState.ABSENT &&
        ticket.availableActions.includes(TicketAction.RESTORATION)
      );
    },
    canCancel(ticket: Ticket): boolean {
      return ticket.status == TicketState.APPLIED;
    },
    async onClickConfirm() {
      console.log('onClickConfirm');
      try {
        await service.applyTickets(
          this.selection.map(i => {
            return { ticketId: i.id, status: TicketState.CONFIRMED };
          })
        );

        this.showSuccessMessage('예약 확정', '예약 확정되었습니다.');
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async onClickUse() {
      console.log('onClickUse');
      try {
        await service.applyTickets(
          this.selection.map(i => {
            return { ticketId: i.id, status: TicketState.USED };
          })
        );

        this.showSuccessMessage('사용 처리', '사용 처리되었습니다.');
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async onClickAbsent() {
      console.log('onClickAbsent');
      try {
        await service.applyTickets(
          this.selection.map(i => {
            return { ticketId: i.id, status: TicketState.ABSENT };
          })
        );

        this.showSuccessMessage('불참 처리', '불참 처리되었습니다.');
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async onClickRestoreConfirmation(ticket: Ticket) {
      console.log('onClickRestoreConfirmation:', ticket);
      try {
        await service.restoreConfirmation(ticket.id, '사유 입력 안함');
        this.showSuccessMessage('예약 확정 취소', '예약 확정 취소되었습니다.');
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async onClickRestoreUsing(ticket: Ticket) {
      console.log('onClickRestoreUsing:', ticket);
      try {
        await service.restoreUsing(ticket.id, '사유 입력 안함');
        this.showSuccessMessage('사용 처리 취소', '사용 처리 취소되었습니다.');
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async onClickRestoreAbsence(ticket: Ticket) {
      console.log('onClickRestoreAbsence:', ticket);
      try {
        await service.restoreAbsence(ticket.id, '사유 입력 안함');
        this.showSuccessMessage('불참 처리 취소', '불참 처리 취소되었습니다.');
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    onClickCancel(ticket: Ticket) {
      this.$router.push(this.$wlink.booking(ticket.bookingItem.booking.id));
    },
    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
  },
});
