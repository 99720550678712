











































import Vue from 'vue';
import BookingMonthCalendar from './BookingMonthCalendar.vue';

export default Vue.extend({
  name: 'BookingCalendar',
  components: { BookingMonthCalendar },
  data() {
    return {
      current: 'month',
      tabs: [
        {
          text: '월간',
          value: 'month',
        },
        // {
        //   text: '주간',
        //   value: 'week',
        // },
        {
          text: '숨김',
          value: '',
        },
      ],
    };
  },
  computed: {
    iconName(): string {
      if (this.current === 'month') {
        return 'iconTopArrow';
      } else {
        return 'iconDownArrow';
      }
    },
  },
  methods: {
    refresh(): void {
      console.log('refresh');
      if (this.current == 'month') {
        (this.$refs['month'] as any).refresh();
      }
    },
    changeTab() {
      if (this.current === 'month') {
        this.current = '';
      } else {
        this.current = 'month';
      }
    },
    onSelectSchedule(scheduleId: string): void {
      console.log('select:', scheduleId);
      this.$router.push(`schedule/${scheduleId}`);
    },
    onSelectTicket(ticketBookingId: string): void {
      console.log('select:', ticketBookingId);
      this.$router.push(`view/${ticketBookingId}`);
    },
  },
});
