










import Vue from 'vue';
import BookingInfoView from '../components/BookingInfoView.vue';
import BookingItemList from '../components/BookingItemList.vue';
import { BOOKING_QUERY } from '../graphql/query';
export default Vue.extend({
  name: 'BookingViewContainer',
  components: { BookingInfoView, BookingItemList },
  data() {
    return {
      bookingId: this.$route.params.id,
      booking: {},
    };
  },
  apollo: {
    booking: {
      query: BOOKING_QUERY,
      variables(): { id: string } {
        return {
          id: this.bookingId,
        };
      },
      update: data => {
        console.log(data.booking.booking.items);
        return data.booking.booking;
      },
    },
  },
  methods: {
    async refetchBooking() {
      console.log('refetch');
      await this.$apollo.queries.booking.refetch();
    },
  },
});
