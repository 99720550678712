export enum BookingState {
  APPLIED = 'APPLIED',
  CONFIRMED = 'CONFIRMED',
  USED = 'USED',
  CANCELED = 'CANCELED',
  ABSENT = 'ABSENT',
}

export const BookingStateLabels: { value: BookingState; label: string }[] = [
  {
    value: BookingState.APPLIED,
    label: '예약 대기',
  },
  {
    value: BookingState.CONFIRMED,
    label: '예약 확정',
  },
  {
    value: BookingState.USED,
    label: '사용 완료',
  },
  {
    value: BookingState.CANCELED,
    label: '취소 완료',
  },
  {
    value: BookingState.ABSENT,
    label: '예약 불참',
  },
];
