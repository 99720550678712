








































































import { Table, TableColumn, DatePicker } from 'element-ui';
import { BookingState } from '../model/BookingState';
import { TicketState } from '../model/TicketState';
import { TicketAction } from '../model/TicketAction';
import Instant from '../../../components/Labels/Instant.vue';
import TicketStatusLabel from './controls/TicketStatusLabel.vue';
import { ContainerMixin } from '@/common/mixin/containerMixin';

interface Booking {
  id: string;
  bookingConfirmationEnabled: boolean;
  schedule: {
    id: string;
    term: {
      startedAt: number;
      endedAt: number;
      duration: number;
    };
  } | null;
  items: BookingItem[];
}

interface BookingItem {
  id: string;
  status: BookingState;
  ticketCount: number;
  indexNo: number;
  replies: Reply[];
  tickets: Ticket[];
}

interface Ticket {
  id: string;
  availableActions: TicketAction;
  scheduleTerm: {
    startedAt: number;
    endedAt: number;
    duration: number;
  } | null;
  confirmedAt: number;
  usedAt: number;
  status: TicketState;
}

interface Reply {
  id: string;
  title: string;
  answer: string;
  selections: { label: string; value: string }[];
}

interface TicketRow {
  item: BookingItem;
  ticket: Ticket;
  firstRow: boolean;
}

interface Inquiry {
  question: string;
  answer: string;
}

export default ContainerMixin.extend({
  name: 'BookingItemList',
  mixins: [ContainerMixin],
  components: {
    Instant,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    TicketStatusLabel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    scheduleStartedAt(): number {
      return this.item.ticket.scheduleTerm?.startedAt || 0;
    },
    canConfirm(): boolean {
      return this.item.ticket.availableActions.includes(
        TicketAction.CONFIRMATION
      );
    },
    canUse(): boolean {
      return this.item.ticket.availableActions.includes(TicketAction.USING);
    },
    canRestoreConfirmation(): boolean {
      return (
        this.item.ticket.status == TicketState.CONFIRMED &&
        this.item.ticket.availableActions.includes(TicketAction.RESTORATION)
      );
    },
    canRestoreUsing(): boolean {
      // 사용 처리, 불참 처리 취소
      return (
        (this.item.ticket.status == TicketState.USED ||
          this.item.ticket.status == TicketState.ABSENT) &&
        this.item.ticket.availableActions.includes(TicketAction.RESTORATION)
      );
    },
    replies(): Inquiry[] {
      const replies = this.item.item.replies.map((reply: Reply) => {
        return {
          question: `Q. ${reply.title}`,
          answer: `A. ${reply.answer}${reply.selections
            .map(i => i.label)
            .join(', ')}`,
        };
      });
      return replies;
    },
  },
  methods: {
    onClickConfirm() {
      this.$emit('confirm', this.item);
    },
    onClickRestoreConfirmation() {
      this.$emit('restoreConfirmation', this.item);
    },
    onClickRestoreUsing() {
      this.$emit('restoreUsing', this.item);
    },
    onClickUse() {
      this.$emit('use', this.item);
    },
    getReply(reply: Reply): Inquiry {
      return {
        question: `Q. ${reply.title}`,
        answer: `A. ${reply.answer}${reply.selections
          .map(i => i.label)
          .join(', ')}`,
      };
    },
  },
});
