























































































































































































import Vue from 'vue';
import { BIconSearch } from 'bootstrap-vue';
import { Select, Option } from 'element-ui';
import HostSearchInput from '@/components/Host/HostSearchInput.vue';
import { BookingState, BookingStateLabels } from '../model/BookingState';
import { BookingFilterQuery } from '../model/BookingFilter';
import VueRouter from 'vue-router';
import { toStringArray } from '@/common/util/toStringArray';
import { BookingTermType } from '@/domain/booking/model/TicketFilter';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { BookingTermTypeLabels } from '@/domain/booking/model/BookingTermTypeLabel';
import { isHostAdmin } from '@/env';

export default ContainerMixin.extend({
  name: 'BookingSearchFilterForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    HostSearchInput,
    BIconSearch,
  },
  data() {
    const query = this.$route.query as BookingFilterQuery;
    let searchTarget = 'productTitle';
    let searchKeyword = '';

    if (query.productTitleLike) {
      searchTarget = 'productTitle';
      searchKeyword = query.productTitleLike;
    } else if (query.productIds) {
      searchTarget = 'productId';
      searchKeyword = toStringArray(query.productIds)?.join(',') || '';
    } else if (query.itemNameLike) {
      searchTarget = 'itemName';
      searchKeyword = query.itemNameLike;
    }

    return {
      searcHostId: query.hostId || '',
      searchTarget: searchTarget,
      searchKeyword: searchKeyword,
      totalCount: 0,
      productSearchOptions: [
        { value: 'productTitle', text: '상품명' },
        { value: 'productId', text: '상품ID' },
        { value: 'itemName', text: '옵션명' },
      ],
      bookingStatusIn:
        typeof query.statusIn !== 'string' // TODO: 개선 필요
          ? query.statusIn?.map(i => i as BookingState) ||
            BookingStateLabels.map(i => i.value)
          : [query.statusIn as BookingState],
      customerSearchKeyword: query.customerSearchKeyword || '',
      bookingStateLabels: BookingStateLabels,
      visible: false,
      showFilterForm: false,
      bookingTermTypeLabels: BookingTermTypeLabels,
      term: {
        startedAt: 0,
        endedAt: 0,
      },
      searchTermType: BookingTermType.APPLYING,
      buttonLoading: false,
      isAdmin: !isHostAdmin(),
    };
  },
  computed: {
    searchKeywordPlaceholder(): string {
      if (this.searchTarget === 'productId') {
        return '상품 아이디를 , 로 구분해 입력해주세요.';
      } else {
        return '검색어를 입력해주세요.';
      }
    },
    iconName(): string {
      return this.showFilterForm ? 'iconTopArrow' : 'iconDownArrow';
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    clear() {
      this.searcHostId = '';
      this.searchTarget = 'productTitle';
      this.searchKeyword = '';
      this.bookingStatusIn = BookingStateLabels.map(i => i.value);
      this.customerSearchKeyword = '';

      this.$router.push({ query: undefined }).catch();
    },
    buildFilter() {
      const filter: BookingFilterQuery = {};

      if (this.searchKeyword) {
        if (this.searchTarget === 'productTitle') {
          filter.productTitleLike = this.searchKeyword;
        } else if (this.searchTarget === 'productId') {
          filter.productIds = this.searchKeyword.split(',').map(i => i.trim());
        } else if (this.searchTarget === 'itemName') {
          filter.itemNameLike = this.searchKeyword;
        }
      }

      if (this.customerSearchKeyword) {
        filter.customerSearchKeyword = this.customerSearchKeyword;
      }

      filter.hostId = this.searcHostId !== '' ? this.searcHostId : undefined;
      filter.statusIn = this.bookingStatusIn;

      if (this.term && this.searchTermType) {
        filter.startedAt = this.term.startedAt.toString();
        filter.endedAt = this.term.endedAt.toString();
        filter.termTypes = [this.searchTermType];
      }

      return filter;
    },
    showOrHideFilter() {
      this.showFilterForm = !this.showFilterForm;
    },
    async excelExport(): Promise<boolean | void> {
      if (this.term.startedAt === 0 || this.term.endedAt === 0) {
        this.showErrorMessage('엑셀 추출 실패!', {
          message: '조회 기간을 다시 확인해 주세요. ',
        });

        return false;
      }

      if (this.term.startedAt > this.term.endedAt) {
        this.showErrorMessage('엑셀 추출 실패!', {
          message: '조회 기간을 다시 확인해 주세요. ',
        });

        return false;
      }

      this.buttonLoading = true;
      const filter = this.buildFilter();
      this.$emit('export', filter, (loading: boolean) => {
        this.buttonLoading = loading;
        return loading;
      });
    },
    refresh() {
      const filter = this.buildFilter();

      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
    openFilter(): void {
      this.showAlert({
        message: '기간 설정은 3개월 이내로 해주세요.',
      });
    },
  },
});
