









































































































































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { BOOKING_LIST_QUERY } from '../graphql/query';
import BookingStatusLabel from './controls/BookingStatusLabel.vue';
import BookingItemCard from './BookingItemCard.vue';
import { BookingFilter, BookingFilterQuery } from '../model/BookingFilter';
import { isHostAdmin } from '../../../env';
import { BookingState, BookingStateLabels } from '../model/BookingState';
import { toStringArray } from '@/common/util/toStringArray';
import xlsx from 'xlsx';
import { BookingService } from '@/domain/booking/service/BookingService';
import { apolloClient } from '@/apolloClient';
import { BookingExportRequest } from '@/domain/booking/model/BookingExportRequest';
import { getConstants, getDomainName } from '@/env';

interface Booking {
  id: string;
  status: BookingState;
  customer: {
    id: string;
    nickname: string;
    mobileNumber: string;
    gender: string;
  };
  order: {
    id: string;
  };
  info: {
    id: string;
    name: string;
    options: { id: string; name: string }[];
    product: {
      id: string;
      title: string;
    };
    host: {
      id: string;
      name: string;
    };
    origin: {
      type: string;
    };
  };
  schedule: {
    id: string;
    term: {
      startedAt: number;
      endedAt: number;
      duration: number;
    };
  } | null;
  purchaseCount: number;
  counts: {
    total: number;
    applied: number;
    confirmed: number;
    used: number;
    absent: number;
  };
}

interface Connection<T> {
  totalCount: number;
  edges: Edge<T>[];
}

interface Edge<T> {
  node: T;
}

const service = new BookingService(apolloClient);

export default Vue.extend({
  name: 'BookingSearchList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
    BookingStatusLabel,
    BookingItemCard,
  },
  data() {
    return {
      loading: 0,
      bookings: [],
      totalCount: 0,
      size: 10,
      isHostAdmin: isHostAdmin(),
      bookingExportRequest: {} as BookingExportRequest,
      constants: getConstants(),
      domainName: getDomainName(),
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    filter(): BookingFilter {
      const query = this.$route.query as BookingFilterQuery;
      const hostId = isHostAdmin()
        ? this.$store.state.userToken.hostId
        : query.hostId;
      return {
        hostId: hostId || null,
        scheduleId: query.scheduleId || null,
        statusIn:
          toStringArray(query.statusIn)?.map(i => i as BookingState) || null,
        productIds: toStringArray(query.productIds) || null,
        productTitleLike: query.productTitleLike || null,
        itemNameLike: query.itemNameLike || null,
        customerSearchKeyword: query.customerSearchKeyword || null,
      };
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
    onClickDetail(booking: Booking) {
      console.log(booking);
      this.$router.push(`view/${booking.id}`);
    },
    onClickOption(booking: Booking) {
      if (booking.schedule) {
        this.$router.push(`schedule/${booking.schedule.id}`);
      }
    },
    getGenderName(booking: Booking) {
      console.log('booking', booking);
      let text = '-';
      switch (booking.customer.gender) {
        case 'M':
          text = '남성';
          break;
        case 'F':
          text = '여성';
          break;
      }
      return text;
    },
    getOptionName(booking: Booking) {
      if (booking.schedule) {
        const startedAt = this.$moment(booking.schedule.term.startedAt);
        const endedAt = this.$moment(booking.schedule.term.endedAt);
        return (
          startedAt.format('YYYY-MM-DD HH:mm') +
          ' ~ ' +
          endedAt.format('YYYY-MM-DD HH:mm')
        );
      }

      return booking.info.name;
    },
    formatNumber(num?: number | string | null): string {
      if (num === null || Number.isNaN(Number(num))) {
        return '0';
      }

      const formatIterator = (num: string): string[] => {
        if (num.length > 3) {
          return formatIterator(num.substring(0, num.length - 3)).concat(
            num.substring(num.length - 3)
          );
        }
        return [num];
      };

      return formatIterator(Math.abs(Number(num)).toString()).join(',') || '0';
    },
    refresh() {
      this.$apollo.queries.bookings.refresh();
    },
    async makeSheet(
      filterQuery: BookingFilterQuery,
      loading: (x: boolean) => boolean
    ) {
      this.filter.term = {
        startedAt: Number(filterQuery.startedAt),
        endedAt: Number(filterQuery.endedAt),
      };

      this.filter.termTypes = filterQuery.termTypes;

      const result = await service.requestExcelExport(this.filter);
      const requestId = result.data.requestBookingExcelExport.id;

      loading(true);
      const requestPolling = setInterval(async () => {
        const bookingExportRequest = await this.getBookingExportRequest(
          requestId
        );
        console.log(bookingExportRequest);
        if (bookingExportRequest.status.toString() == 'COMPLETE') {
          clearInterval(requestPolling);
          loading(false);
          window.open(bookingExportRequest.url, '_blank');
        }
      }, 1500);
    },
    async getBookingExportRequest(id: string): Promise<BookingExportRequest> {
      return await service.getBookingExportRequest(id);
    },
    getChannelId(booking: Booking): string {
      if (booking.info.origin.type == 'FRIP') {
        return `O${booking.order.id}`;
      }
      return `P${booking.info.product.id}`;
    },
  },
  apollo: {
    bookings: {
      query: BOOKING_LIST_QUERY,
      variables(): { page: number; size: number; filter: BookingFilter } {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      // manual: true,
      result(
        result: ApolloQueryResult<{
          booking: { bookings: Connection<Booking> };
        }>
      ) {
        this.totalCount = result.data.booking.bookings.totalCount;
      },
      update: (data: { booking: { bookings: Connection<Booking> } }) =>
        data.booking.bookings.edges.map(edge => edge.node),
    },
  },
});
