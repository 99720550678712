import gql from 'graphql-tag';

export const BOOKING_LIST_QUERY = gql`
  query GetBookingListByFilter(
    $filter: BookingFilterInput
    $page: Int
    $size: Int
  ) {
    booking {
      bookings(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            status
            customer {
              id
              nickname
              mobileNumber
              gender
            }
            order {
              id
            }
            info {
              id
              name
              options {
                id
                name
              }
              product {
                id
                title
              }
              host {
                id
                name
              }
              origin {
                type
              }
            }
            schedule {
              id
              term {
                startedAt
                endedAt
                duration
              }
            }
            purchaseCount
            counts {
              total
              applied
              confirmed
              used
              absent
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

export const BOOKING_QUERY = gql`
  query GetBooking($id: ID!) {
    booking {
      booking(id: $id) {
        id
        status
        bookingConfirmationEnabled
        order {
          id
        }
        customer {
          id
          nickname
          mobileNumber
          gender
        }
        info {
          id
          name
          options {
            id
            name
          }
          product {
            id
            title
          }
          host {
            id
            name
          }
          origin {
            type
          }
        }
        expiredAt
        schedule {
          id
          term {
            startedAt
            endedAt
            duration
          }
        }
        purchaseCount
        items {
          id
          status
          ticketCount
          indexNo
          replies {
            id
            title
            indexNo
            answer
            selections {
              label
              value
            }
          }
          tickets {
            id
            availableActions
            scheduleTerm {
              startedAt
              endedAt
              duration
            }
            confirmedAt
            usedAt
            status
            sequence
          }
        }
        counts {
          total
          applied
          confirmed
          used
          absent
        }
      }
    }
  }
`;

export const SCHEDULES_QUERY = gql`
  query GetScheduleByTerm(
    $filter: ScheduleFilterInput
    $page: Int
    $size: Int
  ) {
    product {
      schedules(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            term {
              startedAt
              duration
              endedAt
            }
            saleTerm {
              startedAt
              endedAt
            }
            status
            booking {
              counts {
                total
                applied
                confirmed
                used
                absent
              }
            }
          }
        }
      }
    }
  }
`;

export const SCHEDULE_QUERY = gql`
  query GetSchedule($id: ID!) {
    product {
      schedule(id: $id) {
        id
        product {
          id
          title
        }
        term {
          startedAt
          endedAt
          duration
        }
        saleTerm {
          startedAt
          endedAt
        }
        status
        items {
          id
          name
        }
      }
    }
  }
`;

export const TICKETS_BY_SCHEDULE_QUERY = gql`
  query GetTicketsBySchedule($scheduleId: String!, $page: Int!, $size: Int!) {
    booking {
      tickets(
        filter: { scheduleIds: [$scheduleId] }
        page: $page
        size: $size
      ) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            availableActions
            status
            scheduleTerm {
              startedAt
              endedAt
              duration
            }
            confirmedAt
            usedAt
            bookingItem {
              id
              indexNo
              booking {
                id
                info {
                  id
                  name
                }
                bookingConfirmationEnabled
                customer {
                  id
                  nickname
                  gender
                  email
                  mobileNumber
                }
              }
              replies {
                id
                title
                answer
                selections {
                  label
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const TICKET_SCHEDULES_QUERY = gql`
  query GetTicketSchedules($page: Int, $size: Int, $filter: TicketFilterInput) {
    booking {
      tickets(page: $page, size: $size, filter: $filter) {
        edges {
          node {
            id
            status
            bookingItem {
              id
              booking {
                id
              }
            }
            scheduleTerm {
              startedAt
              endedAt
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

export const CHECK_CANCELABLE_BY_SCHEDULE = gql`
  query CheckCancelableBySchedule($scheduleId: String!) {
    booking {
      checkCancelableBySchedule(scheduleId: $scheduleId) {
        available
        errors {
          code
          indexNo
          booking {
            id
            status
          }
          message
        }
      }
    }
  }
`;

/**
 * settlement domain의 쿼리이지만, 실질적으로 booking domain에서 사용하기 때문에 booking domain 폴더에 추가.
 */
export const CHECK_EXISTENCE_OF_INVOICE_BY_TICKET_INFO = gql`
  query checkExistenceOfInvoiceByTicketInfo(
    $bookingId: String!
    $indexNo: Int!
    $sequence: Int!
  ) {
    settlement {
      checkExistenceOfInvoiceByTicketInfo(
        bookingId: $bookingId
        indexNo: $indexNo
        sequence: $sequence
      )
    }
  }
`;

export const BOOKING_COUNTS_LIST_QUERY = gql`
  query GetBookingCountsByScheduleIds($scheduleIds: [String!]!) {
    booking {
      bookingCountsByScheduleIds(scheduleIds: $scheduleIds) {
        count
        scheduleId
      }
    }
  }
`;
