



















































































































































































































import { Table, TableColumn, DatePicker } from 'element-ui';
import { BookingState } from '../model/BookingState';
import { TicketState } from '../model/TicketState';
import { TicketAction } from '../model/TicketAction';
import Instant from '../../../components/Labels/Instant.vue';
import TicketStatusLabel from './controls/TicketStatusLabel.vue';
import BookingDetailCard from './BookingDetailCard.vue';
import { BookingService } from '../service/BookingService';
import { apolloClient } from '@/apolloClient';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { isHostAdmin } from '@/env';

interface Booking {
  id: string;
  bookingConfirmationEnabled: boolean;
  schedule: {
    id: string;
    term: {
      startedAt: number;
      endedAt: number;
      duration: number;
    };
  } | null;
  items: BookingItem[];
}

interface BookingItem {
  id: string;
  status: BookingState;
  ticketCount: number;
  indexNo: number;
  replies: Reply[];
  tickets: Ticket[];
}

interface Ticket {
  id: string;
  availableActions: TicketAction;
  scheduleTerm: {
    startedAt: number;
    endedAt: number;
    duration: number;
  } | null;
  confirmedAt: number;
  usedAt: number;
  status: TicketState;
  sequence: number;
}

interface Reply {
  id: string;
  title: string;
  answer: string;
  selections: { label: string; value: string }[];
}

interface Inquiry {
  question: string;
  answer: string;
}

interface TicketRow {
  item: BookingItem;
  ticket: Ticket;
  firstRow: boolean;
}

const service = new BookingService(apolloClient);

export default ContainerMixin.extend({
  name: 'BookingItemList',
  mixins: [ContainerMixin],
  components: {
    Instant,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    TicketStatusLabel,
    BookingDetailCard,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data(): {
    scheduleTermModal: boolean;
    startedAt: string | null;
    ticketId: string | null;
    // attrs: any[];
    hour: string;
    minute: string;
  } {
    return {
      scheduleTermModal: false,
      startedAt: this.$moment().format('YYYY-MM-DDTHH:mm'),
      hour: '',
      minute: '',
      ticketId: null,
    };
  },
  computed: {
    booking: function (): Booking {
      return this.value as Booking;
    },
    items: function (): BookingItem[] {
      return this.booking.items || [];
    },
    attachedToSchedule(): boolean {
      return this.booking.schedule ? true : false;
    },
    rows: function (): TicketRow[] {
      return this.items.flatMap(i =>
        i.tickets.map((t, index) => {
          return {
            item: i,
            ticket: t,
            firstRow: index == 0,
          } as TicketRow;
        })
      );
    },
    attrs(): any {
      return {
        highlight: {
          start: { fillMode: 'solid', style: { background: '#0075EF' } },
          base: {
            fillMode: 'light',
            contentStyle: { color: '#000000' },
            style: { background: '#F3F3F3' },
          },
          end: { fillMode: 'solid', style: { background: '#0075EF' } },
        },
      };
    },
    isValidDate(): boolean {
      const hour = Number(this.hour);
      const minute = Number(this.minute);
      return hour > 0 && hour < 24 && minute >= 0 && minute < 60;
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    getTableSpan({
      row,
      columnIndex,
    }: {
      row: TicketRow;
      column: any;
      rowIndex: number;
      columnIndex: number;
    }): { rowspan: number; colspan: number } {
      if (columnIndex < 3) {
        if (row.firstRow) {
          return {
            rowspan: row.item.ticketCount,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }

      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    getReply(reply: Reply) {
      return `Q. ${reply.title}\n A. ${
        reply.answer + reply.selections.map(i => i.label).join(', ')
      }
        `;
    },
    getScheduleStartedAt(row: TicketRow): number {
      return row.ticket.scheduleTerm?.startedAt || 0;
    },
    refetchBooking() {
      this.$emit('refetch');
    },
    canConfirm(row: TicketRow): boolean {
      return row.ticket.availableActions.includes(TicketAction.CONFIRMATION);
    },
    canUse(row: TicketRow): boolean {
      return row.ticket.availableActions.includes(TicketAction.USING);
    },
    canRestoreConfirmation(row: TicketRow): boolean {
      return (
        row.ticket.status == TicketState.CONFIRMED &&
        row.ticket.availableActions.includes(TicketAction.RESTORATION)
      );
    },
    canRestoreUsing(row: TicketRow): boolean {
      // 사용 처리 취소
      return (
        row.ticket.status == TicketState.USED &&
        row.ticket.availableActions.includes(TicketAction.RESTORATION)
      );
    },
    canRestoreAbsent(row: TicketRow): boolean {
      //  불참 처리 취소
      return (
        row.ticket.status == TicketState.ABSENT &&
        row.ticket.availableActions.includes(TicketAction.RESTORATION)
      );
    },
    async onClickConfirm(row: TicketRow) {
      this.ticketId = row.ticket.id;
      if (this.attachedToSchedule) {
        await service.confirm(this.ticketId);
        this.showSuccessMessage('예약 확정', '예약 확정되었습니다.');
      } else {
        this.scheduleTermModal = true;
      }
    },
    async confirmTicket() {
      const startDate = this.startedAt ? new Date(this.startedAt) : null;
      startDate?.setHours(Number(this.hour), Number(this.minute));
      const startedAt = startDate?.getTime();
      if (!startedAt) {
        this.showAlert({
          message: '예약일을 설정해주세요',
          type: 'warning',
        });
      } else if (startedAt < new Date().getTime()) {
        this.showAlert({
          message: '프립 진행 일정은 과거일 수 없습니다.',
          type: 'primary',
          size: 'sm',
        });
      } else if (this.ticketId) {
        const scheduleTerm = {
          startedAt: startedAt,
          duration: 0, // 일단은 0으로 처리해도 무방하므로 0으로 처리함
        };
        try {
          await service.confirm(this.ticketId, scheduleTerm);
          this.showSuccessMessage('예약 확정', '예약 확정되었습니다.');
        } catch (error) {
          this.showErrorAlert(error);
        }
        this.scheduleTermModal = false;
        this.resetTicketConfirmData();
      }
    },
    resetTicketConfirmData() {
      this.startedAt = this.$moment().format('YYYY-MM-DDTHH:mm');
      this.ticketId = null;
    },
    async onClickUse(row: TicketRow) {
      console.log('onClickUse:', row.ticket);
      try {
        await service.use(row.ticket.id);
        this.showSuccessMessage('사용 처리', '사용 처리되었습니다.');
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async onClickRestoreConfirmation(row: TicketRow) {
      console.log('onClickRestoreConfirmation:', row.ticket);
      try {
        await service.restoreConfirmation(row.ticket.id, '사유 입력 안함');
        this.showSuccessMessage('예약 확정 취소', '예약 확정 취소되었습니다.');
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async onClickRestoreUsing(row: TicketRow) {
      console.log('onClickRestoreUsing:', row.ticket);
      try {
        if (
          isHostAdmin() &&
          (await service.checkExistenceOfInvoiceByTicketInfo(
            this.booking.id,
            row.item.indexNo,
            row.ticket.sequence
          ))
        ) {
          return this.$modal.show({
            title: '사용 처리 취소 실패',
            html: '이미 지급서가 생성된 티켓이기 때문에, 사용 처리 취소가 불가능합니다.',
            type: 'warning',
          });
        }
        await service.restoreUsing(row.ticket.id, '사유 입력 안함.');
        this.showSuccessMessage('사용 처리 취소', '사용 처리 취소되었습니다.');
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async onClickRestoreAbsence(row: TicketRow) {
      console.log('onClickRestoreAbsence:', row.ticket);
      try {
        await service.restoreAbsence(row.ticket.id, '사유 입력 안함.');
        this.showSuccessMessage('불참 처리 취소', '불참 처리 취소되었습니다.');
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    closeScheduleTermModal() {
      this.scheduleTermModal = false;
    },
    formatNumber(num?: number | string | null): string {
      if (num === null || Number.isNaN(Number(num))) {
        return '0';
      }

      const formatIterator = (num: string): string[] => {
        if (num.length > 3) {
          return formatIterator(num.substring(0, num.length - 3)).concat(
            num.substring(num.length - 3)
          );
        }
        return [num];
      };

      return formatIterator(Math.abs(Number(num)).toString()).join(',') || '0';
    },
  },
});
