import gql from 'graphql-tag';

export const PAUSE_SCHEDULE = gql`
  mutation pauseSchedule($id: ID!) {
    pauseSchedule(id: $id) {
      id
      status
    }
  }
`;

export const PAUSE_SCHEDULES = gql`
  mutation pauseSchedules($ids: [ID!]!) {
    pauseSchedules(ids: $ids) {
      id
      status
    }
  }
`;

export const OPEN_SCHEDULE = gql`
  mutation openSchedule($id: ID!) {
    openSchedule(id: $id) {
      id
      status
    }
  }
`;

export const SCHEDULES_DELETE_MUTATION = gql`
  mutation deleteSchedules($ids: [ID!]!) {
    deleteSchedules(ids: $ids) {
      id
      status
    }
  }
`;
