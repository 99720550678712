




























import Vue from 'vue';
import BookingStatusLabel from './controls/BookingStatusLabel.vue';
import { BookingState } from '../model/BookingState';

interface Booking {
  id: string;
  status: BookingState;
  customer: {
    id: string;
    nickname: string;
    mobileNumber: string;
    gender: string;
  };
  info: {
    id: string;
    name: string;
    options: { id: string; name: string }[];
    product: {
      id: string;
      title: string;
    };
    host: {
      id: string;
      name: string;
    };
  };
  schedule: {
    id: string;
    term: {
      startedAt: number;
      endedAt: number;
      duration: number;
    };
  } | null;
  purchaseCount: number;
  counts: {
    total: number;
    applied: number;
    confirmed: number;
    used: number;
    absent: number;
  };
}

export default Vue.extend({
  name: 'BookingItemCard',
  components: {
    BookingStatusLabel,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getOptionName(booking: Booking) {
      if (booking.schedule) {
        const startedAt = this.$moment(booking.schedule.term.startedAt);
        const endedAt = this.$moment(booking.schedule.term.endedAt);
        return (
          startedAt.format('YYYY-MM-DD HH:mm') +
          ' ~ ' +
          endedAt.format('YYYY-MM-DD HH:mm')
        );
      }

      return booking.info.name;
    },
    onClickOption(booking: Booking) {
      if (booking.schedule) {
        this.$router.push(`schedule/${booking.schedule.id}`);
      }
    },
  },
});
