



































import { ScheduleState } from '@/domain/product/model/product/enum';
import Vue from 'vue';
import { ScheduleInfo } from '../service/BookingService';

export default Vue.extend({
  name: 'ScheduleWithBooking',
  props: {
    schedule: {
      type: Object,
    },
  },
  computed: {
    info(): ScheduleInfo {
      return this.schedule as ScheduleInfo;
    },
    time(): string {
      return new Date(this.info.term.startedAt).toTimeString().substr(0, 5);
    },
    themeColor(): string {
      switch (this.schedule.status) {
        case ScheduleState.OPENED:
          return 'primary';
        case ScheduleState.CANCELED:
        case ScheduleState.FINISHED:
        case ScheduleState.CLOSED:
          return 'gray-100';
        case ScheduleState.SOLD_OUT:
          return 'gray-100';
        case ScheduleState.PAUSED:
          return 'warning-100';
        default:
          return 'primary-50';
      }
    },
    textColor(): string {
      switch (this.schedule.status) {
        case ScheduleState.OPENED:
          return 'white';
        case ScheduleState.CANCELED:
        case ScheduleState.FINISHED:
        case ScheduleState.CLOSED:
          return 'frip-gray-600';
        case ScheduleState.SOLD_OUT:
          return 'frip-gray-600';
        case ScheduleState.PAUSED:
          return 'frip-warning';
        default:
          return 'frip-primary';
      }
    },
  },
  methods: {
    onClickSchedule(): void {
      this.$emit('click', this.info);
    },
  },
});
