import { BookingTermType } from '@/domain/booking/model/TicketFilter';

export const BookingTermTypeLabels: {
  value: BookingTermType;
  label: string;
}[] = [
  {
    value: BookingTermType.APPLYING,
    label: '신청일',
  },
  {
    value: BookingTermType.CONFIRMING,
    label: '예약 확정일',
  },
  {
    value: BookingTermType.DEADLINE,
    label: '신청 마감',
  },
  {
    value: BookingTermType.EXPIRATION,
    label: '만료일',
  },
  {
    value: BookingTermType.STARTING,
    label: '진행 시작일',
  },
  {
    value: BookingTermType.USING,
    label: '사용일',
  },
];
