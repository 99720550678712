import { Term } from '@/common/Term';
import { TicketState } from './TicketState';

export interface TicketFilter {
  bookingIds?: string[] | null;
  customerId: string;
  hostId: string;
  scheduleIds: string[];
  statusIn: TicketState[];
  termWithType: TermWithType;
  unschedules: boolean;
}

export interface TermWithType {
  term: Term;
  type: BookingTermType;
}

export enum BookingTermType {
  APPLYING = 'APPLYING',
  CONFIRMING = 'CONFIRMING',
  DEADLINE = 'DEADLINE',
  EXPIRATION = 'EXPIRATION',
  STARTING = 'STARTING',
  USING = 'USING',
}
