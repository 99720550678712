export enum ScheduleCancellationReason {
  ETC = 'ETC',
  LACK_OF_QUOTA = 'LACK_OF_QUOTA',
  LACK_OF_SUPPLIES = 'LACK_OF_SUPPLIES',
  PERSONAL = 'PERSONAL',
  WEATHER = 'WEATHER',
}

export const ScheduleCancellationReasonLabels: {
  value: ScheduleCancellationReason;
  label: string;
  onlySystem: boolean;
  requireComment: boolean;
}[] = [
  {
    value: ScheduleCancellationReason.LACK_OF_QUOTA,
    label: '최소인원 미달',
    onlySystem: true,
    requireComment: false,
  },
  {
    value: ScheduleCancellationReason.PERSONAL,
    label: '개인 사정',
    onlySystem: false,
    requireComment: false,
  },
  {
    value: ScheduleCancellationReason.WEATHER,
    label: '기상 악화',
    onlySystem: false,
    requireComment: false,
  },
  {
    value: ScheduleCancellationReason.LACK_OF_SUPPLIES,
    label: '준비물(재고) 부족',
    onlySystem: false,
    requireComment: false,
  },
  {
    value: ScheduleCancellationReason.ETC,
    label: '기타',
    onlySystem: false,
    requireComment: true,
  },
];
