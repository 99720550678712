import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  SCHEDULE_CANCEL_MUTATION,
  SCHEDULE_OPEN_MUTATION,
  SCHEDULE_PAUSE_MUTATION,
} from '../graphql/mutation';
import { ScheduleCancellationParam } from '../model/ScheduleCancellationParam';
import {
  PAUSE_SCHEDULES,
  SCHEDULES_DELETE_MUTATION,
} from '@/domain/product/queries/schedule/mutation';

export class ScheduleService {
  constructor(private readonly apollo: ApolloClient<NormalizedCacheObject>) {}

  public async open(scheduleId: string): Promise<void> {
    const result = await this.apollo.mutate({
      mutation: SCHEDULE_OPEN_MUTATION,
      variables: {
        scheduleId: scheduleId,
      },
    });
  }

  public async pause(scheduleId: string): Promise<void> {
    console.log('pause:', scheduleId);
    try {
      const result = await this.apollo.mutate({
        mutation: SCHEDULE_PAUSE_MUTATION,
        variables: {
          scheduleId: scheduleId,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  public async cancel(
    scheduleId: string,
    param: ScheduleCancellationParam
  ): Promise<void> {
    console.log('pause:', scheduleId);
    try {
      const result = await this.apollo.mutate({
        mutation: SCHEDULE_CANCEL_MUTATION,
        variables: {
          scheduleId: scheduleId,
          param: param,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  public async deleteByIds(scheduleIds: string[]): Promise<any> {
    try {
      const result = await this.apollo.mutate({
        mutation: SCHEDULES_DELETE_MUTATION,
        variables: {
          ids: scheduleIds,
        },
      });
      return result;
    } catch (err) {
      console.error(err);
      return {
        error: {
          message: err.message,
          path: [err.path],
        },
      };
    }
  }

  public async pauseByIds(scheduleIds: string[]): Promise<any> {
    try {
      const result = await this.apollo.mutate({
        mutation: PAUSE_SCHEDULES,
        variables: {
          ids: scheduleIds,
        },
      });
      return result;
    } catch (err) {
      console.error(err);
      return {
        error: {
          message: err.message,
          path: [err.path],
        },
      };
    }
  }
}
