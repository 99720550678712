import moment from 'moment';

class CalcendarTools {
  getCurrentYearMonth(): YearMonth {
    const date = new Date();
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    };
  }

  getNextMonth(yearMonth: YearMonth): YearMonth {
    return {
      year: yearMonth.month == 12 ? yearMonth.year + 1 : yearMonth.year,
      month: yearMonth.month == 12 ? 1 : yearMonth.month + 1,
    };
  }

  getPrevMonth(yearMonth: YearMonth): YearMonth {
    return {
      year: yearMonth.month == 1 ? yearMonth.year - 1 : yearMonth.year,
      month: yearMonth.month == 1 ? 12 : yearMonth.month - 1,
    };
  }

  getFirstDay(yearMonth: YearMonth): Date {
    const date = new Date(yearMonth.year, yearMonth.month - 1, 1, 0, 0, 0, 0);
    return date;
  }

  getLastDay(yearMonth: YearMonth): Date {
    const date = new Date(yearMonth.year, yearMonth.month, 0, 0, 0, 0, 0);
    return date;
  }

  toDayEnd(day: Date): Date {
    const date = new Date(
      day.getFullYear(),
      day.getMonth(),
      day.getDate(),
      23,
      59,
      59,
      0
    );
    return date;
  }

  getWeekFirstDay(date: Date): Date {
    const firstDay = new Date(date.getTime());
    const week = date.getDay();
    firstDay.setDate(firstDay.getDate() - week);
    return firstDay;
  }

  getWeekLastDay(date: Date): Date {
    const lastDay = new Date(date.getTime());
    const week = lastDay.getDay();
    lastDay.setDate(lastDay.getDate() + (6 - week));
    return lastDay;
  }

  buildCalendar(yearMonth: YearMonth): Calendar {
    const toDate = new Date();
    const firstDay = this.getFirstDay(yearMonth);
    const lastDay = this.getLastDay(yearMonth);

    const firstOfCalendar = this.getWeekFirstDay(firstDay);
    const lastOfCanceldar = this.getWeekLastDay(lastDay);

    const day = new Date(firstOfCalendar.getTime());

    const calendar: Calendar = {
      weeks: [],
      term: {
        startedAt: firstOfCalendar.getTime(),
        endedAt: this.toDayEnd(lastOfCanceldar).getTime(),
      },
    };

    do {
      const days: CalendarDay[] = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(day.getTime());

        days.push({
          dateStr: moment(date).format('YYYY-MM-DD'),
          date: date,
          term: {
            startedAt: date.getTime(),
            endedAt: this.toDayEnd(date).getTime(),
          },
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
          currentMonth: yearMonth.month == date.getMonth() + 1,
          today:
            date.getFullYear() == toDate.getFullYear() &&
            date.getMonth() == toDate.getMonth() &&
            date.getDate() == toDate.getDate(),
        });

        day.setDate(day.getDate() + 1);
      }

      calendar.weeks.push({
        days: days,
        term: {
          startedAt: days[0].date.getTime(),
          endedAt: this.toDayEnd(days[6].date).getTime(),
        },
      });
    } while (day.getTime() < lastOfCanceldar.getTime());

    return calendar;
  }
}

export interface YearMonth {
  year: number;
  month: number;
}

export interface Calendar {
  weeks: CalendarWeek[];
  term: Term;
}

export interface CalendarWeek {
  days: CalendarDay[];
  term: Term;
}

export interface CalendarDay {
  dateStr: string;
  date: Date;
  term: Term;
  year: number;
  month: number;
  day: number;
  today: boolean;
  currentMonth: boolean;
}

export interface Term {
  startedAt: number;
  endedAt: number;
}

export const calendarTools = new CalcendarTools();
