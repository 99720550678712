import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ScheduleState } from '../../product/model/product/enum';
import {
  BOOKING_EXCEL_REQUEST_QUERY,
  REQUEST_EXCEL_EXPORT_MUTATION,
  TICKET_APPLY_MUTATION,
  TICKET_CONFIRM_MUTATION,
  TICKET_RESTORE_ABSENCE_MUTATION,
  TICKET_RESTORE_CONFIRMATION_MUTATION,
  TICKET_RESTORE_USING_MUTATION,
  TICKET_USE_MUTATION,
} from '../graphql/mutation';
import {
  CHECK_CANCELABLE_BY_SCHEDULE,
  CHECK_EXISTENCE_OF_INVOICE_BY_TICKET_INFO,
  SCHEDULES_QUERY,
  TICKET_SCHEDULES_QUERY,
} from '../graphql/query';
import { BookingFilter } from '../model/BookingFilter';
import { TicketState } from '../model/TicketState';
import moment from 'moment';
import { ScheduleTermParam } from '../model/ScheduleTermParam';
import { Edge } from '@frientrip/domain';
import { Ticket } from '../model/Ticket';
import { BookingTermType } from '../model/TicketFilter';
import { BookingState } from '../model/BookingState';
import { BookingExportRequest } from '@/domain/booking/model/BookingExportRequest';

const MAX_SIZE_SCHEDULE = 300;

export class BookingService {
  constructor(private readonly apollo: ApolloClient<NormalizedCacheObject>) {}

  static getDefaultFilter(): BookingFilter {
    return {
      hostId: null,
      productIds: null,
      productTitleLike: null,
      itemNameLike: null,
      statusIn: null,
      scheduleId: null,
      customerSearchKeyword: null,
    };
  }

  public async checkCancelableBySchedule(
    scheduleId: string
  ): Promise<BookingCancelableResult> {
    const { data } = await this.apollo.query({
      query: CHECK_CANCELABLE_BY_SCHEDULE,
      variables: {
        scheduleId: scheduleId,
      },
    });
    return data.booking.checkCancelableBySchedule;
  }

  public async checkExistenceOfInvoiceByTicketInfo(
    bookingId: string,
    indexNo: number,
    sequence: number
  ): Promise<boolean> {
    const { data } = await this.apollo.query({
      query: CHECK_EXISTENCE_OF_INVOICE_BY_TICKET_INFO,
      variables: {
        bookingId,
        indexNo,
        sequence,
      },
    });
    return data.settlement.checkExistenceOfInvoiceByTicketInfo;
  }

  public async confirm(
    ticketId: string,
    term?: ScheduleTermParam
  ): Promise<void> {
    await this.apollo.mutate({
      mutation: TICKET_CONFIRM_MUTATION,
      variables: {
        ticketId: ticketId,
        term: term ? term : null,
      },
    });
  }

  public async use(ticketId: string): Promise<void> {
    await this.apollo.mutate({
      mutation: TICKET_USE_MUTATION,
      variables: {
        ticketId: ticketId,
      },
    });
  }

  public async applyTickets(
    params: { ticketId: string; status: TicketState }[]
  ): Promise<void> {
    await this.apollo.mutate({
      mutation: TICKET_APPLY_MUTATION,
      variables: {
        params: params,
      },
    });
  }

  public async restoreConfirmation(
    ticketId: string,
    reason: string
  ): Promise<void> {
    await this.apollo.mutate({
      mutation: TICKET_RESTORE_CONFIRMATION_MUTATION,
      variables: {
        ticketId: ticketId,
        reason: reason,
      },
    });
  }

  public async restoreUsing(ticketId: string, reason: string): Promise<void> {
    await this.apollo.mutate({
      mutation: TICKET_RESTORE_USING_MUTATION,
      variables: {
        ticketId: ticketId,
        reason: reason,
      },
    });
  }

  public async restoreAbsence(ticketId: string, reason: string): Promise<void> {
    await this.apollo.mutate({
      mutation: TICKET_RESTORE_ABSENCE_MUTATION,
      variables: {
        ticketId: ticketId,
        reason: reason,
      },
    });
  }

  private async _loadSchedulesWithBooking(
    term: { startedAt: number; endedAt: number },
    page: number,
    hostId?: number
  ): Promise<{ items: ScheduleInfo[]; hasNextPage: boolean }> {
    const { data } = await this.apollo.query({
      query: SCHEDULES_QUERY,
      variables: {
        filter: {
          termWithType: {
            term: term,
            type: ScheduleTermSearchType.STARTING,
          },
          statusNotIn: ['EDITING'],
          hostId: hostId || null,
        },
        page: page,
        size: MAX_SIZE_SCHEDULE,
      },
    });
    console.log(data);
    return {
      items: data.product.schedules.edges.map(
        (i: { node: ScheduleInfo }) => i.node
      ),
      hasNextPage: data.product.schedules.pageInfo.hasNextPage,
    };
  }
  private async _loadTicketSchdules(
    term: { startedAt: number; endedAt: number },
    page: number,
    hostId?: number
  ): Promise<Promise<{ items: ScheduleInfo[]; hasNextPage: boolean }>> {
    const { data } = await this.apollo.query({
      query: TICKET_SCHEDULES_QUERY,
      variables: {
        page: page,
        size: MAX_SIZE_SCHEDULE,
        filter: {
          unscheduled: true,
          termWithType: {
            term: term,
            type: BookingTermType.STARTING,
          },
          hostId: hostId || null,
        },
      },
    });
    const edges: Edge<Ticket>[] = data?.booking.tickets.edges || [];
    const hasNextPage: boolean =
      data?.booking.tickets.pageInfo.hasNextPage || false;

    const tickets = edges.map(edge => edge.node);
    const result = tickets.map(item => {
      return {
        id: item.id,
        term: {
          startedAt: item.scheduleTerm.startedAt,
          endedAt: item.scheduleTerm.endedAt,
          duration: item.scheduleTerm.duration,
        },
        saleTerm: {
          startedAt: 0,
          endedAt: 0,
        },
        status: this.convertToScheduleStatus(item.status), // TODO: 이거 수정 필요함
        booking: {
          id: item.bookingItem.booking.id,
          counts: {
            absent: item.status === TicketState.ABSENT ? 1 : 0,
            applied: item.status === TicketState.APPLIED ? 1 : 0,
            confirmed: item.status === TicketState.CONFIRMED ? 1 : 0,
            used: item.status === TicketState.USED ? 1 : 0,
            total: 1,
          },
        },
        isTicketSchedule: true,
      };
    });

    return {
      items: result,
      hasNextPage: hasNextPage,
    };
  }
  /**
   *
   * @param ticketStatus
   * @returns scheduleStatus
   *
   *  예약 확정한 티켓을 캘린더에 표시하기 위해 상태값을 일정의 상태값으로 바꿈
   *  티켓 취소 상태 -> 일정 취소 상태
   *  티켓 예약 확정 상태 -> 일정 오픈 상태
   *  티켓 사용 처리 상태 -> 일정 마감 상태
   *  그 외는 일정 마감 상태로 처리
   */
  private convertToScheduleStatus(ticketStatus: TicketState): ScheduleState {
    switch (ticketStatus) {
      case TicketState.CANCELED:
        return ScheduleState.CANCELED;
      case TicketState.CONFIRMED:
        return ScheduleState.OPENED;
      case TicketState.USED:
        return ScheduleState.FINISHED;
      default:
        return ScheduleState.FINISHED;
    }
  }
  public async loadSchedulesWithBooking(
    term: {
      startedAt: number;
      endedAt: number;
    },
    hostId?: number
  ): Promise<Record<string, ScheduleInfo[]>> {
    let list: ScheduleInfo[] = [];
    let page = 1;
    let hasNext = false;
    do {
      const { items, hasNextPage } = await this._loadSchedulesWithBooking(
        term,
        page++,
        hostId
      );
      list = list.concat(items);
      hasNext = hasNextPage;
    } while (hasNext);

    page = 1;
    hasNext = false;

    do {
      const { items, hasNextPage } = await this._loadTicketSchdules(
        term,
        page++,
        hostId
      );
      list = list.concat(items);
      hasNext = hasNextPage;
    } while (hasNext);

    console.log('loadSchedulesWithBooking: ', list.length);
    // console.log('list: ', list);
    const result = list.reduce((group, item) => {
      const key = moment(new Date(item.term.startedAt)).format('YYYY-MM-DD');
      if (group[key] == undefined) {
        group[key] = [];
      }
      group[key].push(item);
      return group;
    }, {} as Record<string, ScheduleInfo[]>);
    console.log('result: ', result);

    return result;
  }

  public async requestExcelExport(filter: BookingFilter): Promise<any> {
    try {
      const result = await this.apollo.mutate({
        mutation: REQUEST_EXCEL_EXPORT_MUTATION,
        variables: {
          filter: filter,
        },
      });

      return result;
    } catch (err: any) {
      console.error(err.message);
    }
  }

  public async getBookingExportRequest(
    id: string
  ): Promise<BookingExportRequest> {
    const result = await this.apollo.query({
      query: BOOKING_EXCEL_REQUEST_QUERY,
      variables: {
        id,
      },
    });
    console.log(result);
    return result.data.booking.bookingExportRequest;
  }
}

enum ScheduleTermSearchType {
  DEADLINE = 'DEADLINE',
  STARTING = 'STARTING',
}

export interface ScheduleInfo {
  id: string;
  term: {
    startedAt: number;
    endedAt: number;
    duration: number;
  };
  saleTerm: {
    startedAt: number;
    endedAt: number;
  };
  status: ScheduleState;
  booking: {
    id?: string;
    counts: {
      total: number;
      applied: number;
      confirmed: number;
      used: number;
      absent: number;
    };
  };
  isTicketSchedule?: boolean;
}

export interface SchedulesPerDay {
  dateStr: string;
  schedules: ScheduleInfo[];
}

export interface BookingCancelableError {
  booking: {
    id: string;
    status: BookingState;
  };
  code: string;
  indexNo: number;
  message: string;
}

export interface BookingCancelableResult {
  available: boolean;
  errors: BookingCancelableError[];
}
