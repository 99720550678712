















import Vue from 'vue';
import BookingCalendar from '../components/BookingCalendar.vue';
import BookingSearchFilterForm from '../components/BookingSearchFilterForm.vue';
import BookingSearchList from '../components/BookingSearchList.vue';
import { isHostAdmin } from '@/env';
import { BookingFilterQuery } from '@/domain/booking/model/BookingFilter';

type bookingSearchList = InstanceType<typeof BookingSearchList>;

export default Vue.extend({
  name: 'BookingListContainer',
  components: {
    BookingCalendar,
    BookingSearchFilterForm,
    BookingSearchList,
  },
  data() {
    return {
      isHostAdmin: isHostAdmin(),
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    onRefresh() {
      (this.$refs.list as bookingSearchList).refresh();
    },
    async exportExcel(
      filter: BookingFilterQuery,
      loading: (x: boolean) => boolean
    ) {
      await (this.$refs.list as bookingSearchList).makeSheet(filter, loading);
    },
  },
});
