




import Vue from 'vue';
import { ScheduleStatusLabels } from '../../../product/util/getScheduleStatusLabel';

export default Vue.extend({
  name: 'ScheduleStatusLabel',
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    label: function (): string {
      return (
        ScheduleStatusLabels.find(i => i.value.toString() == this.status)
          ?.label || ''
      );
    },
    variant: function (): string {
      return (
        ScheduleStatusLabels.find(i => i.value.toString() == this.status)
          ?.variant || 'secondary'
      );
    },
  },
});
