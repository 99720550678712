

































































































import Vue from 'vue';
import Instant from '@/components/Labels/Instant.vue';
import { BookingState } from '../model/BookingState';
import { isHostAdmin } from '@/env';
import { getConstants, getDomainName } from '@/env';

interface Booking {
  id: string;
  status: BookingState;
  order: {
    id: string;
  };
  customer: {
    id: string;
    nickname: string;
    mobileNumber: string;
    gender: string;
  };
  items: BookingItem[];
  info: {
    id: string;
    name: string;
    options: { id: string; name: string }[];
    product: {
      id: string;
      title: string;
    };
    host: {
      id: string;
      name: string;
    };
    origin: {
      type: string;
    };
  };
  expiredAt: number | null;
  schedule: {
    id: string;
    term: {
      startedAt: number;
      endedAt: number;
      duration: number;
    };
  } | null;
  purchaseCount: number;
  counts: {
    total: number;
    applied: number;
    confirmed: number;
    used: number;
    absent: number;
  };
}

interface BookingItem {
  id: string;
  status: BookingState;
  ticketCount: number;
  indexNo: number;
}

export default Vue.extend({
  name: 'BookingInfoView',
  components: { Instant },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isHostAdmin: isHostAdmin(),
      constants: getConstants(),
      domainName: getDomainName(),
    };
  },
  computed: {
    booking(): Booking {
      return this.value as Booking;
    },
    productTitle(): string {
      return this.booking.info?.product?.title || '';
    },
    itemName(): string {
      return this.booking.info?.name || '';
    },
    hostName(): string {
      return this.booking.info?.host?.name || '';
    },
    customerName(): string {
      return this.booking.customer?.nickname || '';
    },
    customerMobileNo(): string {
      return this.booking.customer?.mobileNumber || '';
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
    representativeTicketCount(): number {
      return this.booking.items?.length > 0
        ? this.booking.items[0].ticketCount
        : 1;
    },
    customerId(): string {
      console.log(this.booking.customer);
      return this.booking.customer?.id || '';
    },
  },
  methods: {
    getGenderName(booking: Booking): string {
      let text = '-';
      switch (booking.customer?.gender) {
        case 'M':
          text = '남성';
          break;
        case 'F':
          text = '여성';
          break;
      }
      return text;
    },
    onClickMoveToPuchase() {
      this.$router.push(this.$wlink.purchase(this.booking.id));
    },
    onClickMoveToOrder() {
      this.$router.push(this.$wlink.order(this.booking.order.id));
    },
    onClickMoveToChatRoom() {
      let url = '';
      let channelId = '';
      if (this.booking.info.origin.type === 'FRIP') {
        channelId = `O${this.booking.order.id}`;
      } else {
        channelId = `P${this.booking.info.product.id}`;
      }
      if (!this.isHostAdmin) {
        url = `https://www.talkplus.io/apps/${this.constants.talkPlusAppId}/channels/${channelId}`;
      } else {
        url = `${this.domainName}/messageDetail/${channelId}`;
      }
      window.open(url, '_blank');
    },
  },
});
