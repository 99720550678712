export enum TicketState {
  APPLIED = 'APPLIED',
  CONFIRMED = 'CONFIRMED',
  USED = 'USED',
  CANCELED = 'CANCELED',
  ABSENT = 'ABSENT',
}

export const TicketStateLabels: {
  value: TicketState;
  label: string;
  style: string;
}[] = [
  {
    value: TicketState.APPLIED,
    label: '예약 대기',
    style: 'success',
  },
  {
    value: TicketState.CONFIRMED,
    label: '예약 확정',
    style: 'primary',
  },
  {
    value: TicketState.USED,
    label: '사용 완료',
    style: 'default',
  },
  {
    value: TicketState.CANCELED,
    label: '취소 완료',
    style: 'warning',
  },
  {
    value: TicketState.ABSENT,
    label: '예약 불참',
    style: 'danger',
  },
];
