












































































import { ContainerMixin } from '@/common/mixin/containerMixin';
import Instant from '@/components/Labels/Instant.vue';
import { ScheduleState } from '../../product/model/product/enum';
import { SCHEDULE_QUERY } from '../graphql/query';
import { apolloClient } from '@/apolloClient';
import { ScheduleService } from '../service/ScheduleService';
import ScheduleStatusLabel from './controls/ScheduleStatusLabel.vue';
import ScheduleCancelPopup from './ScheduleCancelPopup.vue';

interface Schedule {
  id: string;
  product: {
    id: string;
    title: string;
  };
  term: {
    startedAt: number;
    endedAt: number;
    duration: number;
  };
  saleTerm: {
    startedAt: number;
    endedAt: number;
  };
  items: {
    id: string;
    name: string;
  }[];
  status: ScheduleState;
}

const service = new ScheduleService(apolloClient);

export default ContainerMixin.extend({
  components: { Instant, ScheduleStatusLabel, ScheduleCancelPopup },
  name: 'BookingScheduleView',
  props: {
    scheduleId: {
      type: String,
    },
  },
  data() {
    return {
      schedule: {} as Schedule,
    };
  },
  computed: {
    productTitle: function (): string {
      return this.schedule.product?.title || '';
    },
    expiredForSale(): boolean {
      if (!this.schedule.saleTerm) {
        return false;
      }
      const now = new Date();
      return now.getTime() > this.schedule.saleTerm?.endedAt;
    },
    status: function (): ScheduleState {
      return this.schedule.status || ScheduleState.EDITING;
    },
    scheduleOpended: function (): boolean {
      return (
        this.schedule.status == ScheduleState.OPENED && !this.expiredForSale
      );
    },
    schedulePuased: function (): boolean {
      return (
        this.schedule.status == ScheduleState.PAUSED && !this.expiredForSale
      );
    },
    canCancelByStatus: function (): boolean {
      return (
        this.schedule.status != ScheduleState.FINISHED &&
        this.schedule.status != ScheduleState.CANCELED
      );
    },
  },
  methods: {
    async onClickOpen(): Promise<void> {
      try {
        await service.open(this.schedule.id);
      } catch (err) {
        this.showErrorAlert(err, '판매 재개 실패');
      }
    },
    async onClickPause(): Promise<void> {
      return this.$modal.show(
        {
          title: '예약 중지',
          html: '예약 중지를 하면 해당 일정은 더이상 판매되지 않습니다. <br />진행하시겠습니까?',
          type: 'warning',
        },
        async () => {
          try {
            await service.pause(this.schedule.id);
          } catch (err) {
            this.showErrorAlert(err, '예약 중지 실패');
          }
        }
      );
    },
    onClickCancel(): void {
      const popup = this.$refs.ScheduleCancelPopup as InstanceType<
        typeof ScheduleCancelPopup
      >;
      popup?.open(this.schedule.id);
    },
    onSuccessCancel(): void {
      console.log('onSuccessCancel');
      this.$emit('refresh', true);
    },
  },
  apollo: {
    schedule: {
      query: SCHEDULE_QUERY,
      variables(): { id: string } {
        return {
          id: this.scheduleId,
        };
      },
      update: data => data.product.schedule,
    },
  },
});
